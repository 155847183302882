<template>
  <div class="directive" :class="{ inMobile: isMobile }">
    <h3 v-html="titleTransed" v-if="isTitleNewLine"></h3>
    <h3 v-text="title" v-else></h3>
    <p v-html="contentTransed" v-if="isContentNewLine"></p>
    <p v-text="content" v-else></p>
    <p class="warn" v-html="warnTransed" v-if="isWarnNewLine"></p>
    <p class="warn" v-text="warn" v-else></p>
  </div>
</template>

<script>
export default {
  props: {
    directiveInfo: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      warn: {
        type: String,
      },
      isTitleNewLine: {
        type: Boolean,
      },
      isContentNewLine: {
        type: Boolean,
      },
      isWarnNewLine: {
        type: Boolean,
      },
      isMobile: {
        type: Boolean,
      },
    },
  },

  components: {},
  data() {
    return {
      title: this.directiveInfo.title,
      content: this.directiveInfo.content,
      warn: this.directiveInfo.warn,
      isTitleNewLine: this.directiveInfo.isTitleNewLine,
      isContentNewLine: this.directiveInfo.isContentNewLine,
      isWarnNewLine: this.directiveInfo.isWarnNewLine,
      isMobile: this.directiveInfo.isMobile,
    };
  },
  watch: {},
  mounted() {},
  computed: {
    titleTransed() {
      if (this.title !== null) {
        return this.title.split("\n").join("<br />");
      } else {
        return this.title;
      }
    },
    contentTransed() {
      if (this.content !== null) {
        return this.content.split("\n").join("<br />");
      } else {
        return this.content;
      }
    },
    warnTransed() {
      if (this.warn !== null) {
        return this.warn.split("\n").join("<br />");
      } else {
        return this.warn;
      }
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.modify {
  .directive {
    padding: 105px 0 0 0;
  }
}
.mobile.inQr {
  .directive {
    &.inMobile {
      h3 {
        margin: 0;
      }
    }
  }
}
.directive {
  &.inMobile {
    h3 {
      margin: 0 0 20px 0;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 34px;
      letter-spacing: normal;
      text-align: center;
      color: #146f83;
      font-family: 'SUIT';
      &.qr {
        margin: 0;
      }
    }
  }
  h3 {
    margin: 0 0 22px 0;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 41px;
    letter-spacing: normal;
    text-align: center;
    color: #146f83;
    font-family: "SUIT";
  }
  p {
    font-family: "SUIT";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 29px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    &.warn {
      color: #ff6565;
    }
  }
}
</style>
