<template>
  <div class="app-context expanded">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <join-progress :progressInfo="progressInfo"></join-progress>
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join">
        <div class="store-btn-wrap row">
          <button type="button" class="active" @click="addInfo()">
            소개정보 입력
          </button>
          <button type="button" class="addInfo" @click="goMain()">
            메인으로
          </button>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import JoinProgress from "@/components/join/ProgressSub";
import Directive from "@/components/join/Directive";
import AppFooter from "@/components/AppFooter";
import { mapMutations } from "vuex";
export default {
  components: {
    AppHeader,
    JoinProgress,
    Directive,
    AppFooter
  },
  data() {
    return {
      progressInfo: {
        activeNum: 1
      },
      headerOption: {
        navOrder: 1
      },
      directiveInfo: {
        title: "입력완료",
        isTitleNewLine: false,
        content:
          "검색정보가 정상적으로 입력되었습니다.\n 마이페이지에서 검색정보를 관리할 수 있습니다. ",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false
      }
    };
  },
  created() {},
  mounted() {},
  computed: {
  },
  methods: {
    ...mapMutations("util", ["SET_PASSED_URL"]),
    next() {
      this.$router.push("/").catch(()=>{});
    },
    addInfo() {
      this.SET_PASSED_URL("manage");
      this.$router.push("/v/mypage/prmanage").catch(()=>{});
    },
    goMain() {
      this.$router.push("/").catch(()=>{});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.join {
  .store-btn-wrap {
    width: 390px;
    button {
      font-size: 23px;
      line-height: 34px;
      &.addInfo {
        background: #474747;
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .store-btn-wrap {
    button.active {
      position: relative;
      top: -40px;
      left: 107px;
    }
    button.addInfo {
      position: relative;
      top: 40px;
      left: -107px;
    }
  }
}

@media screen and (max-width: 350px) {
  .store-btn-wrap {
    button.active {
      left: 80px;
    }
    button.addInfo {
      left: -135px;
    }
  }
}

@media screen and (max-width: 320px) {
  .store-btn-wrap {
    button.active {
      position: relative;
      top: -40px;
      left: 73px;
    }
    button.addInfo {
      position: relative;
      top: 40px;
      left: -141px;
    }
  }
}
</style>
